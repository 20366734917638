/* src/website/components/booking/CustomDatePicker.css */

/* Ensure the date picker calendar appears above other elements */
.react-datepicker-popper-custom {
    z-index: 1000;
  }
  
  /* Adjust the calendar's positioning on small screens */
  @media screen and (max-width: 480px) {
    .react-datepicker-popper-custom[data-placement^="bottom"] {
      left: 0 !important;
      top: 40px !important;
      transform: translateX(0) !important;
    }
  
    .react-datepicker {
      width: 100% !important;
      margin: 0 auto !important;
    }
  }
  