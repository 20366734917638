/* src/website/components/features/ImageGalleryDisplay.css */

/* Override Slick Slider default styles for dots */
.slick-dots {
    bottom: -30px;
  }
  
  .slick-dots li button:before {
    font-size: 12px;
    color: rgb(88, 10, 223);
  }
  
  .slick-dots li.slick-active button:before {
    color: rgb(88, 10, 223);
  }
  
  /* Remove default margin that might affect layout */
  .slick-slider {
    margin: 0;
  }
  
  /* Ensure thumbnail images don't exceed their container */
  .slick-slide img {
    display: block;
  }
  
  /* Highlight active thumbnail with a border */
  .slick-slide.slick-current img {
    border: 3px solid rgb(204, 191, 192);
  }
  
  /* Hide arrows on mobile via media queries as a fallback */
  @media (max-width: 480px) {
    /* Removed arrow hiding to ensure arrows are always visible */
    /* If you still want to hide arrows on mobile, uncomment the lines below */
    /*
    .slick-prev,
    .slick-next {
      display: none !important;
    }
    */
  }
  